<!--
 * @FilePath: tab-pane-temp.vue
 * @Author: syf
 * @Date: 2024-09-29 14:42:45
 * @LastEditTime: 2024-09-29 15:13:59
 * @Descripttion: 
-->
<template>
    <div class="form">
        <span :class="search.shop_type == 2 ? 'active' : ''" @click="ShopType(2)">门店</span>
        <!-- |<span :class="search.shop_type == 1 ? 'active' : ''" @click="ShopType(1)">门诊</span> -->
        <span>市场
            <el-select v-model="search.market_id" placeholder="请选择" @change="marketChange" clearable >
                <el-option v-for="item in MarketList" :key="item.value" :label="item.lable" :value="item.value">
                </el-option>
            </el-select>
        </span>
        <span>门店
            <el-select v-model="search.shop_id" placeholder="请选择" clearable >
                <el-option v-for="item in newShopList" :key="item.value" :label="item.lable" :value="item.value">
                </el-option>
            </el-select>
        </span>
        <!-- <span>
            <el-radio-group v-model="search.time_type">
                <el-radio :label="1">当日</el-radio>
                <el-radio :label="2">近七天</el-radio>
                <el-radio :label="3">月度</el-radio>
                <el-radio :label="4">年度</el-radio>
            </el-radio-group>

        </span> -->
        <span>
            <!-- <el-date-picker style="margin-left: 20px" v-model="search_dateTime" type="daterange" value-format="yyyy-MM-dd"
                format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker> -->
            时间
            <el-date-picker
                v-model="start_time"
                type="date"
                placeholder="开始日期"   value-format="yyyy-MM-dd">
                </el-date-picker>
                至
                <el-date-picker
                v-model="end_time"
                type="date"
                placeholder="结束日期"   value-format="yyyy-MM-dd">
                </el-date-picker>
        </span>
        <span>
            <el-button @click="searchBtn" type="primary">搜索</el-button>
        </span>
    </div>
</template>

<script>
import {
  getShopData,
} from "@/api/newBoard";
export default {
    name: 'XinyiMiddlePlatformWebTabPaneTemp',
    props: {
        search: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            search_dateTime:[],
            newShopList:[],
            ShopList: [],
            MarketList: [],
            end_time:"",
            start_time:"",
        };
    },
    mounted() {},
    methods: {
        //根据市场获取门店
        marketChange(){
            this.newShopList = this.ShopList.filter(item=>item.market_id==this.search.market_id)
            this.search.shop_id="";
        },
        // 公共搜索-获取门店及市场数据
        getShopDataInfo() {
            getShopData({ shop_type: this.search.shop_type }).then(res => {
                this.newShopList = res.data.shop;
                this.ShopList = res.data.shop;
                this.MarketList = res.data.market
            });
        },
        searchBtn() {
            this.search_dateTime = [this.start_time,this.end_time];
          
            this.$emit('searchBtn', this.search_dateTime)
        },
        ShopType(val) {
            this.search.shop_type = val;
            this.getShopDataInfo();
        }
    },
};
</script>
<style scoped>
.active {
  color: #0055ff;
}
.form {

overflow: hidden;
background-color: #fff;
padding: 10px 0;
border-radius: 4px;
margin-top: 20px;
}

.form span {

padding: 10px;
}
</style>