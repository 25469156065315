/*
 * @FilePath: newBoard.js
 * @Author: 孙亚飞
 * @Date: 2024-09-28 20:19:26
 * @LastEditTime: 2024-09-29 17:51:15
 * @Descripttion: 
 */
import axios from '@/utils/axios'
//员工分析
export const getPersonInfo = data =>
  axios.post('/admin/Board/getPersonInfo', data)
    .then(res => res)
export const getPersonData = data =>
  axios.post('/admin/Board/getPersonData', data)
    .then(res => res)

export const getEnterShopCount = data =>
  axios.post('/admin/Board/enterShopCount', data)
    .then(res => res)

export const getEnterShopClassCount = data =>
      axios.post('/admin/Board/classEnterShop', data)
        .then(res => res)

export const getBargain = data =>
  axios.post('/admin/Board/bargain', data)
    .then(res => res)


export const getShopRetainCustomers = data =>
  axios.post('/admin/Board/ShopRetainCustomers', data)
    .then(res => res)

export const getPeriodicityBaoyou = data =>
  axios.post('/admin/Board/periodicityBaoyou', data)
    .then(res => res)

export const getCustomerValue = data =>
  axios.post('/admin/Board/customerValue', data)
    .then(res => res)

export const getAbcdCustomerMd = data =>
  axios.post('/admin/Board/getAbcdCustomer', data)
    .then(res => res)

export const getYearConsumptionData = data =>
  axios.post('/admin/Board/yearConsumptionData', data)
    .then(res => res)

export const getACustomerAnalyse = data =>
  axios.post('/admin/Board/getACustomerAnalyse', data)
    .then(res => res)


export const getACustomerByRate = data =>
  axios.post('/admin/Board/getACustomerByRate', data)
    .then(res => res)

export const getACustomerBy = data =>
  axios.post('/admin/Board/getACustomerBy', data)
    .then(res => res)
export const getACustomerLs = data =>
  axios.post('/admin/Board/getACustomerLs', data)
    .then(res => res)
export const getBCustomerAnalyse = data =>
  axios.post('/admin/Board/getBCustomerAnalyse', data)
    .then(res => res)

export const getBCustomerByRate = data =>
  axios.post('/admin/Board/getBCustomerByRate', data)
    .then(res => res)
export const getBCustomerBy = data =>
  axios.post('/admin/Board/getBCustomerBy', data)
    .then(res => res)
export const getBCustomerLs = data =>
  axios.post('/admin/Board/getBCustomerLs', data)
    .then(res => res)
//倒三角
export const getPyramidInfo = data =>
  axios.post('/admin/Board/getPyramidInfo', data)
    .then(res => res)
//A类集合
export const getACustomerSynthesis = data =>
  axios.post('/admin/Board/getACustomerSynthesis', data)
    .then(res => res)
//B类集合
export const getBCustomerSynthesis = data =>
  axios.post('/admin/Board/getBCustomerSynthesis', data)
    .then(res => res)

//公共搜索条件

export const getShopData = data =>
  axios.post('/admin/Board/getShopData', data)
    .then(res => res)











